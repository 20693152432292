.page-forgot-password {
  // background-color: $auth_background;
  background: linear-gradient(0deg, #53b5e6, #005794);
  position: relative;
  overflow: hidden;
}

.forgot-password-wrapper {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: url('/images/bg-top.png');
    background-repeat: no-repeat;
    background-position: 85% 100%;
    transform: rotate(180deg);
  }

  .ornament {
    position: absolute;
    border-radius: 2rem;
    background: rgba(0, 176, 240, 0.5);
    transform: rotate(55deg);
    z-index: 0;
    right: 50px;
    bottom: -60%;
    height: 50rem;
    width: 40rem;
  }

  .logo {
    padding: 21px 0 0 0;
  }

  .orbs1 {
    position: absolute;
    top: 189px;
    left: 110px;

    img {
      width: 72px;
      height: 72px;
    }
  }

  .orbs2 {
    position: absolute;
    top: 268px;
    right: 165px;

    img {
      width: 28px;
      height: 28px;
    }
  }

  h1 {
    margin-bottom: 15px;
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
    text-align: center;
  }

  p {
    margin-bottom: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .btn-link {
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
  }

  .form-forgot-password {
    padding: 30px 0;
    width: 380px;
    max-width: 358px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);

    label {
      color: rgba(255, 255, 255, 0.75);
      font-family: 'Open Sans';
      font-size: 12px;
      line-height: 24px;
    }

    .textbox-form-container {
      border: none;
      border-radius: 5px;
      background-color: #fff;
      overflow: hidden;

      input {
        color: #495057;
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 24px;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 0 8px 0px 8px;
      }
    }

    .btn-send-email {
      color: #ffffff;
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: normal;
      height: 50px;
      width: 163px;
      margin-top: 40px;
    }
  }

  .success-submit {
    padding: 30px 0;
    width: 380px;
    max-width: 358px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);
    text-align: center;

    h1 {
      margin: 30px 0 15px 0;
    }

    p {
      margin-bottom: 40px;
      width: 359px;
    }

    .btn-send-email {
      margin-bottom: 15px;
      min-width: 150px;
      background-color: #60269e;
      font-weight: normal;
    }
  }
}
